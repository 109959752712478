import { StandardButton } from "components/buttons/StandardButton";
import { Reveal } from "components/utils/Reveal";
import { DotGrid } from "./DotGrid";
import styles from "./hero.module.scss";
import { MyLinks } from "components/home/hero/components/MyLinks";
import { OutlineButton } from "components/buttons/OutlineButton";
import GlitchImage from "testing/GlitchImage";
import RainyBG from "testing/RainyBGGrid";
import ParticleSphere from "testing/circle2"

export const Hero = () => {
  return (
    <section className={`section-wrapper ${styles.hero}`}>
      <div className={styles.glitchImageWrapper}>
        {/* <GlitchImage /> */}
        <RainyBG/>
      </div>

      <div className={styles.copyWrapper}>
        <Reveal>
          <h1 className={styles.title}>Her AI Network</h1>
        </Reveal>
        <Reveal>
          <MyLinks/>
        </Reveal>
        {/* <Reveal>
          <p className={styles.aboutCopy}>
            We're building a community of women in AI ...
          </p>
        </Reveal> */}
        <Reveal>
          <OutlineButton
            onClick={() => document.getElementById("contact")?.scrollIntoView()}
          >
            Get Involved
          </OutlineButton>
        </Reveal>
      </div>
    </section>
  );
};
