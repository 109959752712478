import React, { useEffect, useRef } from "react";
import * as THREE from "three";

const ParticleSphere = () => {
  const mountRef = useRef(null);
  const isDragging = useRef(false);
  const previousMousePosition = useRef({ x: 0, y: 0 });
  const rotationSpeed = useRef({ x: 0.002, y: 0.002 }); // Default rotation speed
  const particleSystemRef = useRef(null); // Reference to the particle system

  useEffect(() => {
    let frameId;
    const width = mountRef.current.clientWidth;
    const height = mountRef.current.clientHeight;

    // Create Scene
    const scene = new THREE.Scene();

    // Create Camera
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    camera.position.z = 150; // Move camera back to view the whole sphere

    // Create Renderer
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(width, height);
    renderer.setClearColor("#dad9d2"); // Set background color
    mountRef.current.appendChild(renderer.domElement);

    // Create Particles on Sphere Surface
    const particles = 10000;
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];

    const defaultColor = new THREE.Color("#b8b8b7"); // Particle color
    const radius = 70; // Radius of the sphere

    for (let i = 0; i < particles; i++) {
      const biasFactor = Math.random(); // This can bias toward certain latitudes
      const theta = Math.random() * Math.PI * 2; // Longitude
      const phi = Math.acos(2 * (biasFactor - 0.5)); // Biasing latitude for denser regions

      const x = radius * Math.sin(phi) * Math.cos(theta);
      const y = radius * Math.sin(phi) * Math.sin(theta);
      const z = radius * Math.cos(phi);

      positions.push(x, y, z);
      colors.push(defaultColor.r, defaultColor.g, defaultColor.b);
    }

    geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(positions, 3)
    );
    geometry.setAttribute("color", new THREE.Float32BufferAttribute(colors, 3));

    // Set particles to appear as circles using PointsMaterial
    const material = new THREE.PointsMaterial({
      size: 3,
      vertexColors: true,
      transparent: true,
      opacity: 0.6, // More transparent
      sizeAttenuation: true, // Particle size stays relative when zooming in/out
      alphaTest: 0.5,
      map: new THREE.TextureLoader().load(
        "https://threejs.org/examples/textures/sprites/circle.png"
      ),
    });

    const particleSystem = new THREE.Points(geometry, material);
    scene.add(particleSystem);
    particleSystemRef.current = particleSystem; // Store reference to particle system

    // Mouse events for rotation
    const handleMouseDown = (event) => {
      isDragging.current = true;
      previousMousePosition.current = {
        x: event.clientX,
        y: event.clientY,
      };
    };

    const handleMouseMove = (event) => {
      if (isDragging.current) {
        const deltaX = event.clientX - previousMousePosition.current.x;
        const deltaY = event.clientY - previousMousePosition.current.y;

        // Update the sphere's rotation based on mouse movement
        particleSystem.rotation.y += deltaX * 0.005; // Adjust rotation speed for dragging (slower)
        particleSystem.rotation.x += deltaY * 0.005; // Adjust rotation speed for dragging (slower)

        previousMousePosition.current = {
          x: event.clientX,
          y: event.clientY,
        };
      }
    };

    const handleMouseUp = () => {
      isDragging.current = false; // Stop dragging
    };

    // Animation function
    const animate = () => {
      if (!isDragging.current) {
        // Rotate the sphere continuously when not dragging
        particleSystem.rotation.y += rotationSpeed.current.y;
        particleSystem.rotation.x += rotationSpeed.current.x;
      }
      renderer.render(scene, camera);
      frameId = requestAnimationFrame(animate);
    };

    // Add mouse event listeners
    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    // Start animation
    animate();

    // Cleanup on unmount
    return () => {
      cancelAnimationFrame(frameId);
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  return (
    <div
      ref={mountRef}
      style={{
        width: "100%",
        height: "100vh", // Set height to 100vh to fill the viewport
        background: "#dad9d2", // Background color
      }}
    />
  );
};

export default ParticleSphere;
